import { render, staticRenderFns } from "./DlgLeakageInfo_pop.vue?vue&type=template&id=19c3673b"
import script from "./DlgLeakageInfo_pop.vue?vue&type=script&lang=js"
export * from "./DlgLeakageInfo_pop.vue?vue&type=script&lang=js"
import style0 from "./DlgLeakageInfo_pop.vue?vue&type=style&index=0&id=19c3673b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports