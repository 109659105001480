<template>
    <div style="background-color: #172A46;">
        <!--<div id="waveform_m" ref="waveform_m" style="position: absolute; left: 20px; top: 5px; z-index: 20;"/> -->
        <div v-if="properties!==null"  style="padding-bottom:1rem;">
        <h4 class="facility-info__title" >{{$t('leakage_info.page_title')}}</h4>


           <!-- <div class="mt-3" style="padding: 10px 30px;"   v-if="properties!==null" > -->
            <b-tabs class="popup_tab_page"  v-if="properties!==null" align="center">
              <b-tab :title="$t('leakage_info.properties')" active>

                  

                    <b-row >
                        <b-col style="height: 280px">
                            <!--<FFTChart :url="fft_url" ref="fftchart1" :height="fftchart_height1"/>-->
                             <!--<FFTChart1 :url="fft_url" ref="fftchart" :height="fftchart_height1"/> -->
                          <b-row >
                              <audio controls preload="auto" :src="aurl" style = ";width:70%;transform:scale(0.7);"/>
                         <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                            <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
                              <span class="inner-bg">Y Axis</span> -->
                               <div class="status leak" style="padding: 0 ; margin: 10px -20px; " >
                                  <input type="radio" v-model="vchart" value="0" id="fixed" checked>
                                  <label for="fixed" >{{$t('leakage_info.fixed')}}</label>
                                  <input type="radio" v-model="vchart" value="1" id="proportional">
                                  <label for="proportional" >{{$t('leakage_info.proportional')}}</label>
                              </div>
                          </b-row>
                              <FFTChart1 :url="fft_url"  ref="fftchart" :height="fftchart_height1"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.title')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['w_title']"  disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.contents')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['w_contents']" disabled></b-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.worker')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['worker']" disabled></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.f_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['f_idx']" disabled ></b-input>
                        </b-col>
                    </b-row>
                      <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('facility_info.f_name')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['layer_type']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.start_time')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['measure_start_datetime']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.save_time')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['period']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                    <!--
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">Wave Energy</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['wave_energy']"  disabled ></b-input>
                        </b-col>
                    </b-row>
                    -->
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.strength')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['wave_energy']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                      <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.max_hz')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['wave_max_frequency']" disabled  ></b-input>
                        </b-col>
                    </b-row>


                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.file_name')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-textarea class="facility__input height--auto" rows="2" v-model="properties['file_name']"  disabled ></b-textarea>
                        </b-col>
                    </b-row>
                   <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.device_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['device_sn']" disabled  ></b-input>
                        </b-col>
                    </b-row>      
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.image')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['image']" disabled   ></b-input>
                        </b-col>
                    </b-row>                                  
                   <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.remarks')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['remarks']"   ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.s_cal')}}</label>
                        </b-col>

                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['wave_energy_cal']"  type="number" min=0 ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">Position</label>
                        </b-col>

                        <b-col sm="8">
                            <span style="color: #9d9d9d;">{{properties['X']}}, {{properties['Y']}}</span>                            
                            
                        </b-col>
                    </b-row>                    
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.ai_result')}}</label>
                        </b-col>
                        <b-col sm="6">
                            <b-textarea class="facility__input height--auto" size="sm" v-model="ai_result" disabled ></b-textarea >
                        </b-col>
                        <b-col sm="2">
                            <b-button v-if="!loading" class="mr-2"  size="sm" @click="get_ai_result">
                                <i class="fas fa-sync-alt"></i>
                            </b-button>
                            <span v-if="loading"><img src="@/assets/images/loading.gif" style="width:25px;"/></span>
                        </b-col>
                    </b-row> 
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.probability')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['probability']"  disabled ></b-input>
                        </b-col>
                    </b-row>   
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.leak_result')}}</label>
                        </b-col>
                      
                        <b-col sm="8">
                            <b-select class="facility__input"  style="margin-bottom:5px;" size="sm" v-model="properties['leak_result']" :options="leak_result_option"  ></b-select>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.dataset')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-select class="facility__input" style="margin-bottom:5px;" size="sm" v-model="properties['is_dataset']" :options="is_dataset_option"  ></b-select>
                        </b-col>
                    </b-row>             
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.is_ground')}}</label>
                        </b-col>
                        <b-col sm="8">
                              <b-input  class="facility__input" size="sm" v-model="properties['ground']"  disabled></b-input >
                        </b-col>
                    </b-row>    

                 </b-tab>
                <b-tab title="Photo">
                      <div  style="overflow:auto;height: 700px;">
                    <p v-for="(item,i) in img_list" :key="i">
                            <b-card
                                no-body
                                img-top
                                class="photo-card"
                            >
                                <b-img thumbnail fluid :src="item" alt="image" bottom ></b-img>
                                <!--<b-img thumbnail fluid src="http://localhost:3000/api/get_img/leakage/6/1" alt="Image" bottom></b-img>-->
                            </b-card>      
                    </p>   
                    </div>  
                    <!--b-card
                        no-body
                        class="photo-card"
                        img-top
                    >
                       
                        <b-img thumbnail fluid :src="img1" alt="Image" bottom></b-img>
                    </b-card>
                    <b-card
                        no-body
                        class="photo-card"
                        img-top
                    >
                         <b-img thumbnail fluid :src="img2" alt="Image" bottom></b-img>
                      
                    </b-card>
                    <b-card
                        no-body
                        img-top
                        class="photo-card"
                    >
                         <b-img thumbnail fluid :src="img3" alt="Image" bottom></b-img>
                     
                    </b-card-->
                </b-tab>
            </b-tabs>
            <!--</div> -->
          <b-row class="facility-info-footer-row m-0 mb-3">
<b-col sm="6">
                 <b-button @click="close();" block class="modal-footer__button modal-footer__button-cancel">Close</b-button>
               </b-col>
               <b-col sm="6">
                <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">Apply</b-button>
               </b-col>
      </b-row>
        </div>
    </div>
</template>

<script>
//import WaveSurfer from 'wavesurfer.js';
import FFTChart1 from '@/components/chart/FFTChart1';


export default {
    name: 'DlgLeakageInfo',
    props: {
    },
    data() {
        return {
            properties: null,
       //     waveform_height1: 10,
            fftchart_height1: 180,
            is_playing: false,
       //     wavesurfer: null,
            loading:false,
            fft_url: '',
            aurl : '' ,
             img1:'',
             img2:'',
             img3:'',
             vchart : '0',
             is_dataset_option: [
                { value: '1', text : 'Dataset' },
                { value: '0', text: 'No Dataset' },
                //{ value: 'RT_LEAK_INSIDE', text: 'Leak inside' },
                //{ value: 'RT_USAGE', text: 'Usage' },
                //{ value: 'RT_OTHER', text: 'Other' },
             
            ],
            leak_result_option: [
                { value: 'L', text: 'Leak' },
                //{ value: 'RT_LEAK_INSIDE', text: 'Leak inside' },
                //{ value: 'RT_USAGE', text: 'Usage' },
                //{ value: 'RT_OTHER', text: 'Other' },
                { value: 'M', text : 'Meter is Running' },
                { value: 'N', text : 'No Leak' },
                { value: 'LS', text: 'Leak suspicion' },
            ],
             img_list: [],
             ai_result:'',
        }
    },
 
     watch: {
        vchart() {
               //     if(this.vchart==='0') {
                //        alert("radio button : " + this.vchart) ;
                 //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
               //     }
               var c_url = this.fft_url.split('#') ;
               this.fft_url = c_url[0]+'#'+this.vchart ;
        }
    },
    methods: {
        handleOk() {

        },
       get_ai_result(){
            this.loading = true     
            this.$http.get(this.URL+'api/set_leak_AI_data?idx='+this.properties['id']).then((res) => {
                 if(res.data.check ===1){ 
                    this.loading = false
                    return;
                 }
                 this.ai_result = res.data.msg ;
                 this.loading = false 
            });
            setTimeout(()=>{
                this.loading = false      
             }, 10000)    
        },
        close(){
             this.properties = null;
             window.close();
        },

        show(idx) {
             this.properties = {} ;
             this.img_list =[];
             this.$http.get(this.URL+'api/get_leak_info?idx='+idx).then((res) => {
                if(res.data.length<=0) return

              //  let leak_result = res.data.list[0]['leak_result'] ;

             //   if(leak_result === null)
             //      this.properties['leak_result'] =  "RT_OTHER" ;
              //  this.properties['idx'] =  i ;

                this.properties = res.data.list[0];
               
                 if(this.properties.image_list === null || this.properties.image_list  === undefined){

                  this.img_list.push(this.URL+'api/'+this.properties['img_file1']) 
                  //this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
                  this.img_list.push( this.URL+'api/'+this.properties['img_file2']);
                  this.img_list.push( this.URL+'api/'+this.properties['img_file3']);
                  
                }else{
                  var img = this.properties.image_list.split(',')
                  for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                  }
                }
                /*
                this.img1 = this.URL+'api/'+this.properties['file_path1'];
                this.img2 = this.URL+'api/'+this.properties['file_path2'];
                this.img3 = this.URL+'api/'+this.properties['file_path3'];
                */
                  this.ai_result = this.properties['ai_leak_result'] ;
                this.properties_bak = Object.assign({}, this.properties)

             })


            this.$refs.modal.show();
            /*
            this.wavesurfer = WaveSurfer.create({
                container: '#waveform_m',
                waveColor: 'blue',
                progressColor: 'black',
                autoCenter: true,
        //      height: this.waveform_height1,
                plugins: [
                ]
            });
            this.wavesurfer.on('finish', () => {
                this.is_playing = false
            });
                this.wavesurfer.load(this.URL+'api/get_wav/'+idx);
                 var c_url = this.fft_url.split('?') ;
               
            */
                this.aurl = this.URL+'api/get_wav/'+idx ;
               // this.fft_url = this.URL+"api/get_fft/"+idx;
               this.fft_url = this.URL+"api/get_fft/"+idx+'#'+this.vchart;
             
        },
        apply() {
            
             //   if(localStorage === null || typeof localStorage === undefined){
            if(sessionStorage === null || typeof sessionStorage === "undefined"){
                return
           // }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 1 && sessionStorage.m_id !== this.properties.worker){
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
            
            let params = {} ;
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
            if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                params[keys[i]] = this.properties[keys[i]]
            }
            if(Object.keys(params).length<=0) return
                this.$http.get(this.URL+'api/update_water_leak?idx='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                console.log(res.data);
            })
            window.close();
          //  this.$emit('leaktable_refresh') ;
           // this.$refs.modal.hide();
        }

    },
       mounted () {
             this.properties = {} ;
          //   var s_aurl = "";
             var s_ffturl = "";
              this.ai_result ="";
             //옆에 메뉴를 없어지게 한다.
             document.getElementById("sidebar").style.display='none';
             document.getElementById("Header").style.display='none';
             document.getElementById("menu2").style.display='none';
             document.getElementById("main").style.paddingLeft ='0px' ;
             document.getElementById("main").style.paddingTop ='0px' ;           
             document.getElementsByClassName("content-wrap").item(0).style.height ='100%' ;
             document.getElementById("page-wrapper").style.minWidth = "500px";
            if(typeof this.$store.setting === "undefined") {
                this.$http.get(this.URL+'api/get_setting?m_id='+sessionStorage.m_id).then((res) => {
                    this.$store.setting = res.data
                })
            }
             this.$http.get(this.URL+'api/get_leak_info?idx='+this.$route.query.idx).then((res) => {
                if(res.data.length<=0) return            

              //  let leak_result = res.data.list[0]['leak_result'] ;         
                
             //   if(leak_result === null)
             //      this.properties['leak_result'] =  "RT_OTHER" ;
              //  this.properties['idx'] =  i ;
            
                this.properties = res.data.list[0];   
                if(this.properties.image_list === null || this.properties.image_list  === undefined){

                  this.img_list.push(require('@/assets/noimage1.png')) 
                  //this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
                  this.img_list.push(require('@/assets/noimage1.png'));
                  this.img_list.push(require('@/assets/noimage1.png'));
                  
                }else{
                  var img = this.properties.image_list.split(',')
                  for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                  }
                }    
                
            //    s_aurl = res.data.list[0].file_path;
               this.ai_result = this.properties['ai_leak_result'] ;
                s_ffturl = res.data.list[0].fft_file_path;

                if(s_ffturl === null || s_ffturl=== undefined)
                  s_ffturl = "/get_fft/"+this.$route.query.idx;
                 this.fft_url = this.URL+"api/"+s_ffturl+'#'+this.vchart;

                this.properties_bak = Object.assign({}, this.properties)

                setTimeout(()=>{
                    this.aurl = this.URL+'api/get_wav/'+this.$route.query.idx;
                 }, 200)      

             })

                
            //   this.aurl = s_aurl;
            //   this.fft_url = this.URL+"api/"+s_ffturl+'#'+this.vchart;
            //this.$refs.modal.show();
            
          //  this.fft_url = this.URL+"api/get_fft/"+this.$route.query.idx+'?'+this.vchart;
      
      
    },
    components: {
        FFTChart1: FFTChart1
        //FFTChart1_c:FFTChart1_c
    }
}
</script>

<style lang="scss">
// .facility__label-radio {
//   font-size: 12px;
//   color: #616161;
//   display: inline-block;
//   vertical-align: middle;
//   line-height: 25px;
//   margin-bottom: 0;
//   margin-left: 5px;
//   margin-right: 10px;
// }

// #Header {   display: none; }
// .page-wrapper .page-content {
//      padding: 0;
   
// }
// .facility-info__title {
//     background: white;
// }

// .mb-3 {
//     margin: 1rem!important;
// }
// .nav-tabs {
//      background: white;
//      border-bottom: 1px solid #dee2e6; 
// }
// .tab-content {
//     max-height: 1100px;    
//     overflow-y: hidden;    
//     overflow-x: hidden; 
// }
</style>
